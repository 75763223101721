import React from "react"

const AustraliaFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="#0052b4"
      d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.06 256 .029 256 0c141.384 0 256 114.616 256 256z"
    />
    <g fill="#f0f0f0">
      <path d="M256 0c-.014 0-.029.001-.043.001L256 0zM255.315 256H256v-.685c-.228.229-.456.457-.685.685z" />
      <path d="M256 133.566V.001h-.043C114.592.024 0 114.629 0 256h133.565v-75.212L208.777 256h46.539c.229-.228.457-.456.685-.685v-46.536l-75.213-75.213H256z" />
    </g>
    <g fill="#d80027">
      <path d="M129.515 33.391C89.476 56.19 56.19 89.476 33.391 129.515V256h66.783V100.174H256V33.391H129.515z" />
      <path d="M256 224.519l-90.953-90.952h-31.481c0-.001 0 0 0 0L255.999 256H256v-31.481z" />
    </g>
    <g fill="#f0f0f0">
      <path d="M154.395 300.522l14.05 29.378 31.727-7.333-14.208 29.302 25.514 20.233-31.767 7.16.089 32.564-25.405-20.373-25.404 20.373.089-32.564-31.768-7.16 25.515-20.233-14.21-29.302 31.729 7.333zM383.284 356.174l7.025 14.689 15.864-3.667-7.105 14.651 12.758 10.117-15.884 3.58.044 16.282-12.702-10.187-12.702 10.187.044-16.282-15.883-3.58 12.757-10.117-7.104-14.651 15.863 3.667zM317.933 200.348l7.024 14.69 15.864-3.668-7.104 14.651 12.757 10.117-15.883 3.58.043 16.282-12.701-10.187L305.231 256l.043-16.282-15.883-3.58 12.757-10.117-7.104-14.651 15.864 3.668zM383.284 111.304l7.025 14.69 15.864-3.667-7.104 14.651 12.756 10.116-15.883 3.581.044 16.282-12.702-10.187-12.702 10.187.044-16.282-15.883-3.581 12.756-10.116-7.103-14.651 15.863 3.667zM440.368 178.087l7.024 14.69 15.864-3.668-7.104 14.651 12.757 10.117-15.884 3.581.044 16.281-12.701-10.186-12.702 10.186.043-16.281-15.883-3.581 12.757-10.117-7.104-14.651 15.863 3.668zM399.55 256l5.525 17.006h17.882l-14.467 10.511 5.527 17.005-14.467-10.51-14.466 10.51 5.525-17.005-14.466-10.511h17.881z" />
    </g>
  </svg>
)

const SingaporeFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <circle cx="256" cy="256" r="256" fill="#f0f0f0" />
    <path
      fill="#d80027"
      d="M0 256.001C0 114.616 114.616 0 256 0s256 114.616 256 256.001"
    />
    <g fill="#f0f0f0">
      <path d="M155.826 133.564c0-37.298 26.213-68.456 61.217-76.101-5.38-1.174-10.961-1.811-16.696-1.811-43.031 0-77.913 34.882-77.913 77.913s34.882 77.913 77.913 77.913c5.733 0 11.315-.637 16.696-1.812-35.004-7.645-61.217-38.803-61.217-76.102zM256 61.217l5.526 17.005h17.881L264.94 88.733l5.526 17.005L256 95.229l-14.466 10.509 5.526-17.005-14.466-10.511h17.88z" />
      <path d="M212.625 94.608l5.525 17.006h17.881l-14.466 10.51 5.526 17.005-14.466-10.509-14.468 10.509 5.527-17.005-14.467-10.51h17.881zM299.376 94.608l5.527 17.006h17.88l-14.467 10.51 5.527 17.005-14.467-10.509-14.466 10.509 5.526-17.005-14.466-10.51h17.88zM282.681 144.695l5.526 17.006h17.88l-14.466 10.51 5.526 17.005-14.466-10.509-14.466 10.509 5.526-17.005-14.466-10.51h17.879zM229.32 144.695l5.525 17.006h17.882l-14.467 10.51 5.527 17.005-14.467-10.509-14.467 10.509 5.526-17.005-14.466-10.51h17.881z" />
    </g>
  </svg>
)

const NewZealandFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="#0052b4"
      d="M512 256c0 141.384-114.616 256-256 256S0 397.384 0 256C0 256.061 256 .028 256 0c141.384 0 256 114.616 256 256z"
    />
    <g fill="#f0f0f0">
      <path d="M256 0h-.043.043zM255.315 256H256v-.685c-.228.229-.456.457-.685.685z" />
      <path d="M256 133.565V0h-.043C114.592.024 0 114.629 0 256h133.565v-75.211L208.777 256h46.539c.228-.228.457-.456.685-.685v-46.537l-75.213-75.213H256z" />
    </g>
    <g fill="#d80027">
      <path d="M129.515 33.391C89.476 56.19 56.19 89.476 33.391 129.515V256h66.783V100.174H256V33.391H129.515z" />
      <path d="M256 224.519l-90.953-90.953s-31.481.002-31.481 0v.002L255.999 256H256v-31.481zM443.33 189.959l5.525 17.006h17.881l-14.466 10.51 5.526 17.005-14.466-10.509-14.467 10.509 5.526-17.005-14.467-10.51h17.881zM379.411 312.393l8.289 25.51h26.821l-21.7 15.764 8.29 25.509-21.7-15.765-21.7 15.765 8.29-25.509-21.7-15.764h26.821zM381.825 112.046l6.908 21.259h22.351L393 146.44l6.908 21.258-18.083-13.139-18.082 13.139 6.907-21.258-18.082-13.135h22.35zM320.322 189.217l8.288 25.51h26.822l-21.7 15.764 8.29 25.509-21.7-15.765-21.7 15.765 8.29-25.509-21.7-15.764h26.821z" />
    </g>
  </svg>
)

export default {
  AU: AustraliaFlag,
  NZ: NewZealandFlag,
  SG: SingaporeFlag,
}
